<template>
  <div :style="iconStyle">
    {{ text }}
  </div>
</template>
<script>
export default {
  name: "TextIcon",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    backgroundColor: {
      type: String,
      default: "#12851f",
    },
    size: {
      type: String,
      default: "30px",
    },
  },
  data() {
    return {
      iconStyle: {
        color: this.color,
        backgroundColor: this.backgroundColor,
        borderRadius: "100%",
        width: this.size,
        height: this.size,
        lineHeight: this.size,
        textAlign: "center",
      },
    };
  },
};
</script>
<style lang="scss" scoped></style>
