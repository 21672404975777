<template>
  <van-popup
    v-model="showMap"
    position="bottom"
    :style="{
      height: 'calc(60vh + 80px)',
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
    }"
  >
    <div v-if="chooseType === 'click'">
      <BaiduMap
        id="container"
        :style="mapStyle"
        :ak="baiduMapKey"
        :map-click="false"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="true"
        @click="getClickInfo"
        @moving="syncCenterAndZoom"
        @moveend="syncCenterAndZoom"
        @ready="onBaiduMapReady"
        @zoomend="syncCenterAndZoom"
      >
        <BmControl
          v-if="showControl"
          :offset="{ width: '100%', height: '10px' }"
        >
          <BmAutoComplete
            v-model="keyword"
            :sug-style="{ zIndex: 999999 }"
            @confirm="handleSelect"
          >
            <van-field
              v-model="keyword"
              label=""
              placeholder="请输入地名关键字"
              style="margin: 10px;display: block;"
            />
          </BmAutoComplete>
        </BmControl>
        <BmView style="width: 100%; height: 0px;" />
        <BmMarker
          :position="{ lng: center.lng, lat: center.lat }"
          :dragging="true"
          animation="BMAP_ANIMATION_BOUNCE"
        />
        <!-- <BmControl
          v-if="showKeyword"
          :offset="{ width: '10px', height: '10px' }"
        >
          <BmAutoComplete v-model="keyword" :sug-style="{ zIndex: 999999 }">
            <HInput v-model="keyword" prefix-icon	="el-icon-search" placeholder="请输入地名关键字" style="margin: 10px;display: block;" />
          </BmAutoComplete>
        </BmControl> -->
        <BmGeolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          show-address-bar
          auto-location
        />
        <BmLocalSearch
          :keyword="keyword"
          :auto-viewport="true"
          :panel="false"
        />
      </BaiduMap>
    </div>
    <template v-if="chooseType === 'input'">
      <LocationSearch @handleSelect="locationSelect" />
    </template>
    <div v-if="chooseType === 'click'" style="margin: 10px 0px;padding: 10px;">
      <van-button block type="primary" @click="confirm">确认</van-button>
    </div>
  </van-popup>
</template>
<script>
import {
  BaiduMap,
  BmControl,
  BmView,
  BmAutoComplete,
  BmLocalSearch,
  BmMarker,
  BmGeolocation,
} from "vue-baidu-map";
import LocationSearch from "@/components/locationSearch";
export default {
  name: "Map",
  props: {
    showControl: {
      type: Boolean,
      default: true,
    },
    chooseType: {
      type: String,
      default: "click",
    },
  },
  components: {
    BmControl,
    BmAutoComplete,
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmMarker,
    BmGeolocation,
    LocationSearch,
  },
  computed: {
    baiduMapKey() {
      return process.env.VUE_APP_BAIDU_MAP_KEY;
    },
  },
  data() {
    return {
      showMap: false,
      pointA: null,
      BMap: null, // 地图组件是否就绪
      showMapComponent: true,
      keyword: "", // 地区搜索的信息
      mapStyle: {
        width: "100%",
        height: "60vh",
      },
      center: { lng: 116.404, lat: 39.915 },
      choosedLocation: { province: "", city: "", district: "", addr: "" },
      zoom: 15,
    };
  },
  methods: {
    // 搜索
    handleSelect(item) {
      let map = item.currentTarget.map;
      let point = map.re;
      let res = item.item.value;
      if (this.chooseType === "input") {
        // 调整地图中心位置
        this.center.lng = point.lng;
        this.center.lat = point.lat;
        console.log("获取经纬度", point, "获取详细地址", res);
        const province = res.province;
        const city = res.city;
        const district = res.district;
        let addr = res.business;
        this.choosedLocation = {
          province,
          city,
          district,
          addr,
          ...this.center,
        };
        console.log(this.choosedLocation);
        this.confirm();
      }
    },
    locationSelect(item) {
      if (this.chooseType === "input") {
        // 调整地图中心位置
        this.center.lng = item.lng;
        this.center.lat = item.lat;
        const province = item.province;
        const city = item.city;
        const district = item.district;
        const street = item.street;
        const streetNumber = item.streetNumber;
        let addr = item.addressStr;
        this.choosedLocation = {
          province,
          city,
          district,
          addr,
          street,
          streetNumber,
          ...this.center,
        };
        this.confirm();
      }
    },
    open() {
      this.showMap = true;
    },
    confirm() {
      this.showMap = false;
      this.$emit("choosedLocation", this.choosedLocation);
    },
    // 通过浏览器定位获得我的经纬度
    getMyPoint(callback) {
      var self = this;
      var geolocation = new this.BMap.Geolocation();
      geolocation.getCurrentPosition(function(res) {
        self.pointA = new self.BMap.Point(res.point.lng, res.point.lat); // 通过浏览器获得我的经纬度
        callback && callback();
      });
    },
    // 测量百度地图两个点间的距离
    getDistance(itemPoint) {
      var map = new this.BMap.Map("");
      var pointB = new this.BMap.Point(
        parseFloat(itemPoint.lng),
        parseFloat(itemPoint.lat)
      ); // 店铺的经纬度
      var distance = (map.getDistance(this.pointA, pointB) / 1000).toFixed(2); // 保留小数点后两位
      return distance;
    },
    drawLine(endSite) {
      let BMap = this.BMap;
      let map = new BMap.Map("container", { enableMapClick: false });
      // map.centerAndZoom(new BMap.Point(this.center.lng, this.center.lat), this.zoom);		// 设置中心点（北京）
      map.clearOverlays(); // 清除地图上的覆盖物

      let startSite = this.choosedLocation;
      let start = new BMap.Point(startSite.lng, startSite.lat); // 起点
      let end = new BMap.Point(endSite.lng, endSite.lat); // 终点

      let time = "";
      let lenth = "";
      let searchComplete = (results) => {
        if (transit.getStatus() != BMAP_STATUS_SUCCESS) {
          return;
        } // 检测返回状态
        let plan = results.getPlan(0);
        if (!plan) return;
        time = plan.getDuration(true) + "\n"; //获取时间
        lenth = plan.getDistance(true); //获取距离
        console.log("距离：" + lenth);
        console.log("耗时：" + time);
        lenth = lenth.indexOf("米") !== -1 ? "1" : lenth;
        lenth = Math.round(lenth.replace("公里", "")) + "";
        this.$emit("drawLine", { time, lenth });
      };
      let transit = new BMap.DrivingRoute(map, {
        renderOptions: { map: map, autoViewport: true },
        onSearchComplete: searchComplete,
        onPolylinesSet: function() {
          setTimeout(function() {}, 10);
        },
      });
      transit.search(start, end);
    },
    // ready事件：在你需要二次开发或手动控制其子组件，可以使用在此事件中使用返回的 BMap 类和 map 实例进行手动控制
    onBaiduMapReady(e) {
      console.log("返回BMap类和map实例", e);
      const that = this;
      this.BMap = e.BMap;
      if (this.BMap) {
        // 获取定位地址信息并赋值给声明变量
        // Geolocation 对象的 getCurrentPosition()、watchPosition()、clearWatch() 方法详解 ：https://blog.csdn.net/zyz00000000/article/details/82774543
        const geolocation = new this.BMap.Geolocation();
        // 通过 getCurrentPosition() 方法：获取当前的位置信息
        geolocation.getCurrentPosition((res) => {
          console.log("返回详细地址和经纬度", res);
          const { lng, lat } = res.point;
          const {
            province,
            city,
            district,
            street,
            street_number,
          } = res.address;

          let geoc = new this.BMap.Geocoder();
          geoc.getLocation(res.point, (rs) => {
            const addrComponent = rs.addressComponents;
            const surroundingPois = res.surroundingPois;
            const province = addrComponent.province;
            const city = addrComponent.city;
            const district = addrComponent.district;
            let addr =
              (addrComponent.street || "") + (addrComponent.streetNumber || "");
            if (
              !addr &&
              surroundingPois.length > 0 &&
              surroundingPois[0].title
            ) {
              addr += `${surroundingPois[0].title}`;
            } else if (!addr && surroundingPois.length === 0) {
              addr += addrComponent.address || "";
            }
            that.choosedLocation = {
              province,
              city,
              district,
              addr: res.cb && res.cb.z ? res.cb.z.title : addr,
              ...that.center,
            };
            that.$emit("choosedLocation", that.choosedLocation);
            // that.getMyPoint(() => {
            //   console.log(that.getDistance({
            //     lat: 32.071506,
            //     lng: 118.805584
            //   }) + 'km')
            // });
          });
        });
      }
    },
    chooseLocation() {
      this.keyword = get(this.form, this.propMap["address"]);
    },
    /** *
     * 地图点击事件。
     */
    getClickInfo(e) {
      // 调整地图中心位置
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
      // 此时已经可以获取到BMap类
      if (this.BMap) {
        const that = this;
        // Geocoder() 类进行地址解析
        // 创建地址解析器的实例
        const geoCoder = new this.BMap.Geocoder();
        // getLocation() 类--利用坐标获取地址的详细信息
        // getPoint() 类--获取位置对应的坐标
        geoCoder.getLocation(e.point, function(res) {
          console.log("获取经纬度", e.point, "获取详细地址", res);
          const addrComponent = res.addressComponents;
          const surroundingPois = res.surroundingPois;
          const province = addrComponent.province;
          const city = addrComponent.city;
          const district = addrComponent.district;
          let addr =
            (addrComponent.street || "") + (addrComponent.streetNumber || "");
          if (!addr && surroundingPois.length > 0 && surroundingPois[0].title) {
            addr += `${surroundingPois[0].title}`;
          } else if (!addr && surroundingPois.length === 0) {
            addr += addrComponent.address || "";
          }
          that.choosedLocation = {
            province,
            city,
            district,
            addr: e.cb && e.cb.z ? e.cb.z.title : addr,
            ...that.center,
          };
          that.chooseType === "input" && that.confirm();
        });
      }
    },
    syncCenterAndZoom(e) {
      // 返回地图当前的缩放级别
      this.zoom = e.target.getZoom();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .anchorTL {
  width: 93%;
}
</style>
