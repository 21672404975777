<template>
  <div>
    <div style="height: 220px;">
      <Map
        ref="mapRef"
        @choosedLocation="mapChoosedLocation"
        @drawLine="drawLine"
      />
      <PlaceSelect
        ref="placeSelectRef"
        chooseType="input"
        @choosedLocation="choosedLocation"
      />
      <van-cell-group>
        <div>
          <van-field
            v-model="startPlace"
            label=""
            placeholder="请选择起点"
            disabled
            right-icon="edit"
            type="textarea"
            autosize
            @click-right-icon="editStartPlace"
          >
            <template #left-icon>
              <TextIcon text="起" size="25px" />
            </template>
          </van-field>
        </div>
        <van-field
          v-model="endPlace"
          label=""
          placeholder="请选择终点"
          disabled
          autosize
          type="textarea"
        >
          <template #left-icon>
            <TextIcon text="终" size="25px" />
          </template>
        </van-field>
      </van-cell-group>
      <div style="text-align: center;padding-right: 20px;margin-top: 20px;">
        <div style="margin-bottom: 10px;">
          距离事故点 <font class="ft-cls">{{ distance }}</font>
        </div>
        <div style="margin-bottom: 10px;">
          预估 <font class="ft-cls">{{ estimateTime }}</font
          >到达
        </div>
        <br />
        <div style="margin-bottom: 10px;font-size: 13px;">
          收费方式 {{ order.settleType }}
        </div>
        <div style="font-size: 13px;color: #ee0a24;">
          留言：{{ order.driverMessage }}
        </div>
      </div>
      <div style="padding: 10px;margin-bottom: 120px;">
        <van-button
          block
          type="primary"
          :loading="formLoading"
          loading-text="提交中..."
          @click="go"
          >确认出发</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Map from "@/components/Map";
import PlaceSelect from "@/components/PlaceSelect";
import { request } from "@/api/service";
import { dictFilter } from "@/utils/dictUtils";
import TextIcon from "@/components/TextIcon";
export default {
  name: "GoConfirm",
  components: { Map, PlaceSelect, TextIcon },
  data() {
    return {
      formLoading: false,
      id: null,
      startPlace: null,
      startLng: null,
      startLat: null,
      endPlace: null,
      endLng: null,
      endLat: null,
      distance: null,
      projectCode: null,
      estimateTime: null,
      order: {
        id: null,
        settleType: null,
        driverMessage: null,
        charges: null,
      },
    };
  },
  created() {
    this.init();
    this.setDict();
  },
  mounted() {
    // 待触发 `UniAppJSBridgeReady` 事件后，即可调用 uni 的 API。
    document.addEventListener("UniAppJSBridgeReady", () => {
      uni.getEnv(function(res) {
        console.log("当前环境：" + JSON.stringify(res));
      });
    });
  },
  methods: {
    ...mapActions("dict", ["setDict"]),
    async init() {
      const { id, addr, lng, lat } = this.$route.query;
      const detail = await request({
        url: `/acs-rescue/order/downInfo/${id}`,
        method: "get",
      });
      if (!detail) return;
      // 通过id查询订单详情，初始化订单及终点
      this.id = id;
      this.endPlace = detail.rescueAddr;
      this.endLng = detail.rescueLongitude;
      this.endLat = detail.rescueLatitude;
      this.startPlace = addr;
      this.startLng = lng;
      this.startLat = lat;
      // 规划路线
      this.$refs.mapRef.drawLine(
        {
          lng: this.endLng,
          lat: this.endLat,
        },
        {
          lng,
          lat,
        }
      );
      this.driverId = detail.orderDispatch.driverId;
      this.projectCode = detail.projectCode;
      let settleTypeDict = {
        1: "收现",
        2: "挂账",
        3: "赠送",
      };
      this.order = {
        id: "",
        settleType: settleTypeDict[detail.settleType],
        driverMessage: detail.driverMessage,
        charges:
          detail.orderDispatch.priceType === "1"
            ? (detail.orderDispatch.price || "0.00") + "元"
            : detail.orderDispatch.price +
              "元 含" +
              detail.orderDispatch.mileage +
              "公里，超出后" +
              detail.orderDispatch.pricingMileage +
              "元/公里",
      };
    },
    async go() {
      this.formLoading = true;
      try {
        let km = this.distance;
        km = km.indexOf("米") !== -1 ? "1" : km;
        km = Math.round(km.replace("公里", "")) + "";
        let data = {
          orderId: this.id,
          driverId: this.driverId,
          departureAddress: this.startPlace,
          departureLongitude: this.startLng,
          departureLatitude: this.startLat,
        };
        if (this.projectCode && this.projectCode.charAt(0) === "T") {
          data["predictKm"] = km;
        } else {
          data["plannedMileage"] = km;
        }
        await request({
          url: `/acs-rescue/order/feedback/departure`,
          method: "post",
          data,
        });
        this.formLoading = false;
        this.$toast("已提交");
        uni.reLaunch({
          url: "/pages/index/index?id=" + this.id,
        });
      } catch (e) {
        console.log(e);
        this.formLoading = false;
      }
    },
    editStartPlace() {
      this.$refs.placeSelectRef.open();
    },
    mapChoosedLocation({ lng, lat, addr }) {
      // const query = this.$route.query;
      // this.startPlace = query.addr || addr;
      // this.startLng = query.lng || lng;
      // this.startLat = query.lat || lat;
      // // 规划路线
      // this.$refs.mapRef.drawLine(
      //   {
      //     lng: this.endLng,
      //     lat: this.endLat,
      //   },
      //   {
      //     lng,
      //     lat,
      //   }
      // );
    },
    choosedLocation({ lng, lat, addr, province, city, district, street, streetNumber }) {
      this.startPlace = province + city + district + street + streetNumber + '-' + addr;
      this.startLng = lng;
      this.startLat = lat;
      // 规划路线
      this.$refs.mapRef.drawLine(
        {
          lng: this.endLng,
          lat: this.endLat,
        },
        {
          lng,
          lat,
        }
      );
    },
    drawLine({ time, lenth }) {
      this.distance = Math.round(lenth.replace("公里", "") || 0) + "公里";
      this.estimateTime = time;
    },
  },
};
</script>
<style lang="scss" scoped>
.ft-cls {
  color: #12851f;
  font-weight: bold;
  font-size: 20px;
}
</style>
