<template>
  <div class="map-maker-wrapper">
    <baidu-map
      class="bm-view"
      :ak="baiduMapKey"
      :center="mapCenter"
      :zoom="mapZoom"
      :scroll-wheel-zoom="true"
      @ready="onReady"
    >
    </baidu-map>

    <div class="search-wrap">
      <van-field
        v-model="keyword"
        left-icon="search"
        placeholder="请输入搜索关键词"
        @input="handleSearch"
      />

      <!-- 检索结果 -->
      <div v-show="showResultFlag" class="search-result">
        <div
          v-for="(item, index) in searchResult"
          class="item"
          :key="index"
          @click="handleSelect(item)"
        >
          <p class="title">{{ item.title }}</p>
          <p class="address">{{ item.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
const defaultInfo = {
  lng: 0,
  lat: 0,
  addressStr: "",
  title: "",
  province: "", // 省
  city: "", // 市
  district: "", // 区
};
export default {
  name: "LocationSearch",
  props: {
    lng: {
      type: Number,
      default: null,
    },
    lat: {
      type: Number,
      default: null,
    },
  },
  computed: {
    baiduMapKey() {
      return process.env.VUE_APP_BAIDU_MAP_KEY;
    },
  },
  components: {
    BaiduMap,
  },
  data() {
    return {
      BMap: null,
      map: null,
      mapZoom: 15,
      mapCenter: { lng: 116.404, lat: 39.915 },
      keyword: "",
      searchResult: [], // 检索结果列表
      showResultFlag: false,
      selectInfo: Object.assign({}, defaultInfo),
    };
  },
  methods: {
    // 地图初始化回调
    onReady({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
      // 根据传入经纬度初始化
      const query = this.$route.query;
      if (query.lng && query.lat) {
        this.mapCenter.lng = Number(query.lng);
        this.mapCenter.lat = Number(query.lat);
        this.getAddress(this.mapCenter.lng, this.mapCenter.lat);
      } else if (this.lng && this.lat) {
        this.mapCenter.lng = Number(this.lng);
        this.mapCenter.lat = Number(this.lat);
        this.getAddress(this.mapCenter.lng, this.mapCenter.lat);
      }
    },
    handleSearch() {
      let self = this;
      self.showResultFlag = true;
      self.selectInfo = Object.assign({}, defaultInfo);
      let local = new this.BMap.LocalSearch(this.map, {
        renderOptions: {
          map: this.map,
          selectFirstResult: false,
        },
        onSearchComplete: function(res) {
          if (res && (res.Kr || res.Xr)) {
            self.searchResult = [...(res.Kr || res.Xr)];
          }
        },
      });
      local.search(this.keyword);
    },
    getAddress(lng, lat) {
      let self = this;
      let point = new this.BMap.Point(lng, lat);
      let geoc = new this.BMap.Geocoder();
      geoc.getLocation(point, function(res) {
        self.map.clearOverlays(); //清除地图上所有覆盖物
        self.map.addOverlay(new self.BMap.Marker({ lng, lat }));
        self.mapCenter.lng = lng;
        self.mapCenter.lat = lat;
        self.mapZoom = 15;
      });
    },
    handleSelect(item) {
      let self = this;
      let title = item.title;
      let { lng, lat } = item.marker.point;
      let point = new this.BMap.Point(lng, lat);
      let geoc = new this.BMap.Geocoder();
      geoc.getLocation(point, function(res) {
        let addString = title;
        self.showResultFlag = false;
        self.keyword = addString;
        self.map.clearOverlays(); //清除地图上所有覆盖物
        self.map.addOverlay(new self.BMap.Marker({ lng, lat }));
        self.mapCenter.lng = lng;
        self.mapCenter.lat = lat;
        self.mapZoom = 15;
        self.selectInfo = {
          lng,
          lat,
          addressStr: addString,
          title: title,
          province: res.addressComponents.province,
          city: res.addressComponents.city,
          district: res.addressComponents.district,
          street: res.addressComponents.street,
          streetNumber: res.addressComponents.streetNumber
        };
        self.handleSubmit();
      });
    },
    handleSubmit() {
      console.log(this.selectInfo);
      this.$emit("handleSelect", this.selectInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-maker-wrapper {
  position: relative;
}
.btn-confrim {
  width: 120px;
  height: 56px;
  line-height: 56px;
  background-color: #5ac9d4;
  border-radius: 8px;
  color: #ffffff;
  text-align: center;
}
.bm-view {
  width: 100%;
  height: calc(100vh - 88px);
}
.search-wrap {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 28px;
  .search {
    background-color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    height: 45px;
    .search-img {
      width: 48px;
      height: 48px;
    }
    .search-input {
      flex: 1;
      outline: none;
      border: none;
      background: none;
      font-size: 28px;
      color: #313233;
    }
    .search-btn {
      font-size: 28px;
      font-weight: 600;
      color: #313233;
    }
  }
  .search-result {
    background-color: #fff;
    border-radius: 0px;
    max-height: 55vh;
    overflow-y: auto;
    .item {
      border-bottom: 1px solid #ebeef2;
      padding: 0px;
      &:last-child {
        border-bottom: none;
      }
      .title {
        padding: 0px 35px;
        font-size: 15px;
        font-weight: 600;
        color: #313233;
      }
      .address {
        padding: 0px 35px;
        font-size: 13px;
        font-weight: 400;
        color: #9ca5b3;
      }
    }
  }
}
</style>
